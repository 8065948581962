import './MainLayout.less';
import React, { Component } from 'react';
import { Layout, Spin } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setCurrentFarm, setMenus } from '../../actions';
import PageHeader from './PageHeader';
import PageSider from './PageSider';
import Routes from '../Routes';

const { api, util, WebSocketClient } = window.common;
const { Content } = Layout;

class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.count = 0;
    this.state = { contentVisible: false, siderVisible: false };
    this.getUserInfo();
  }

  /*** 异常捕获 ***/
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    console.error(errorInfo);
  }

  componentWillUnmount() {
    this.ws?.unsubscribe('mainData', this.handleMainData);
    this.ws?.close();
  }


  getUserInfo() {
    api({
      url: '/cust/user/getLoginInfo',
    }).then((res) => {
      const data = res.data;
      if (data.roleWithFarmList && data.roleWithFarmList.length === 1 && !data.roleKey) {
        data.roleKey = data.roleWithFarmList[0].roleKey;
      }
      window.user = data || {};
      if (!window.user.obsFlag && this.test(data)) { return; }//观察者模式不做矿场、角色、nuc校验
      this.getFarmList();
      this.getTableColumns();
      this.initWebSocket(data.serviceTicket);
    });
  }

  getRootDomain = () => {
    const hostname = window.location.hostname.split('.');
    return hostname.slice(-2).join('.');
  }

  initWebSocket(serviceTicket) {
    const domain = this.getRootDomain();
    this.ws = new WebSocketClient(`wss://api.${domain}/ws/cloud/${serviceTicket}`);
    // this.ws = new WebSocketClient('ws://localhost:3000', {});
    this.ws?.connect();
    this.ws?.subscribe('mainData', this.handleMainData);
  }

  handleMainData = (data) => {
    console.log(data);
  }

  test = (data) => {
    const enabledRoles = data.roleWithFarmList.filter(a => a.enabled).map(a => a.roleKey);

    if (data.roleWithFarmList && data.roleWithFarmList.length > 0) {
      if (!data.roleKey) {
        window.location.href = '#/home/role-select';
        return true;
      }
      if (!enabledRoles.includes(data.roleKey)) {
        window.location.href = '#/home/no-nuc';
        return true;
      }
    } else {
      window.location.href = '#/home/no-farm';
      return true;
    }
  }

  getFarmList() {
    api({
      url: '/cust/farm/list',
    }).then((res) => {
      window.farmList = res.data || [];
      if (window.farmList.length > 0) {
        const farm = window.farmList.find(a => a.minerFarmId === window.Bitmain.minerFarmId);
        if (!farm) {
          window.Bitmain.minerFarmId = window.farmList[0].minerFarmId;
        }
        this.props.setCurrentFarm(farm || window.farmList[0]);
        localStorage.setItem('bitmain', JSON.stringify(window.Bitmain));
        this.setContentVisible();
        // 因为某些特定矿场菜单展示不一样，所以需要处理完矿场列表才能获取菜单
        this.getMenus();
      } else {
        window.location.href = '#/home/no-farm';
      }
    });
  }

  getMenus() {
    api({
      url: '/ucs/permission/role/menu/render',
    }).then((res) => {
      this.props.setMenus({ menus: res.data, });
      this.setState({ siderVisible: true });
      this.setContentVisible();
    });
  }


  getTableColumns() {
    window.tableColumns = {};
    api({
      url: '/cust/user/getObject',
    }).then((res) => {
      if (res.data && res.data.objectList) {
        res.data.objectList.forEach((item) => {
          window.tableColumns[item.key] = item.value.split(',');
        });
      }
      this.setContentVisible();
    });
  }

  setContentVisible() {
    this.count++;
    if (this.count === 3) {
      this.setState({ contentVisible: true });
    }
  }

  render() {
    return (
      <Layout>
        <Layout>
          {this.state.siderVisible && <PageSider />}
          {
            this.state.contentVisible
            && (
              <Content className="bm-container">
                <PageHeader />
                <Routes />
              </Content>
            )
          }
        </Layout>
        <div className="bm-loading"><Spin className="loading-icon" size="large" /></div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  farm: state.farm,
});

const mapDispatchToProps = dispatch => ({
  setCurrentFarm: currentFarm => dispatch(setCurrentFarm(currentFarm)),
  setMenus: menus => dispatch(setMenus(menus)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainLayout)
);
