
import util from './util';

export default class WebSocketClient {
  constructor(url, options = {}) {
    this.ws = null;
    this.url = url;
    this.subscribers = new Map();
    this.id = util.uuid();
    this.reconnectTimer = null; //重连定时器
    this.reconnectInterval = 10000; // 重连间隔
    // this.reconnectAttempts = 0; // 重连次数
    // this.maxReconnectAttempts = 10; // 最大重连次数，暂时不设置最大重连
    this.heartBeattimer = null; //心跳定时器
    this.heartbeatInterval = 10000; // 心跳间隔
    this.onOpenCallbacks = [];
    this.isUserClose = false; // 用户是否主动关闭
  }

  initSocket() {
    this.isUserClose = false;
    this.ws = new WebSocket(this.url);

    this.ws.onopen = () => {
      console.log('WebSocket连接已建立');
      this.heartBeat();
      // this.reconnectAttempts = 0;
      this.onOpenCallbacks.forEach(callback => callback());
    };

    this.ws.onmessage = (event) => {
      const { type, ...data } = JSON.parse(event.data);
      // 没有权限不重连
      if (type === 'unauthorized') {
        this.close();
      }
      this.handleMessage(data);
    };

    this.ws.onclose = () => {
      console.log('WebSocket连接已关闭');
      clearInterval(this.heartBeattimer);
      clearInterval(this.reconnectTimer);
      this.reconnectTimer = null;
      this.heartBeattimer = null;
      this.reconnect();
    };

    this.ws.onerror = (error) => {
      console.error('WebSocket错误:', error);
    };
  }

  // 初始化WebSocket连接
  connect(callback) {
    this.initSocket(callback);
  }

  // 重连机制
  reconnect() {
    // 如果链接已经建立，或者是用户主动关闭，不需要重连
    if (this.ws?.readyState === WebSocket.OPEN || this.isUserClose) { return; }
    this.reconnectTimer = setTimeout(() => {
      this.connect();
    }, this.reconnectInterval);
  }

  // 心跳检测
  heartBeat() {
    if (this.heartBeattimer) {
      clearInterval(this.heartBeattimer);
    }
    this.heartBeattimer = setInterval(() => {
      if (this.ws?.readyState === WebSocket.OPEN) {
        this.send({
          id: this.id,
          type: 'ping',
          timestamp: Date.now()
        });
      }
    }, this.heartbeatInterval);
  }

  // 订阅特定类型的消息
  subscribe(type, callback) {
    if (!this.subscribers.has(type)) {
      this.subscribers.set(type, new Set());
    }
    this.subscribers.get(type).add(callback);
  }

  // 取消订阅
  unsubscribe(type, callback) {
    if (this.subscribers.has(type)) {
      this.subscribers.get(type).delete(callback);
    }
  }

  // 处理接收到的消息
  handleMessage(data) {
    const { key, content, ...payload } = data;
    if (this.subscribers.has(key)) {
      try {
        const jsonData = JSON.parse(window.atob(content));
        this.subscribers.get(key).forEach(callback => callback(jsonData, payload));
      } catch (e) {
        console.error(e);
      }
    }
  }

  send(message) {
    const messageStr = JSON.stringify(message);
    if (this.ws?.readyState === WebSocket.OPEN) {
      this.ws?.send(messageStr);
    } else {
      console.error('WebSocket未连接');
    }
  }

  open(callback) {
    this.onOpenCallbacks.push(callback); // 注册连接建立后的回调函数
  }

  // 主动关闭连接
  close() {
    if (this.ws) {
      this.ws?.close();
      this.ws = null;
      this.isUserClose = true;
      console.log('主动关闭WebSocket连接');
    }
  }
}
