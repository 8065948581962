import locale from '../i18n';
import api from './api';
import util from './util';
import enums from './enums';
import links from './links';
import store from '../store';
import WebSocketClient from './socket';

// 检查前端版本是否更新，有更新则刷新页面
const checkVersion = () => {
  api({
    url: '/version.json',
    params: {
      v: Date.now(),
    },
  }, { apiServer: '' }).then((res) => {
    const version = res.data;
    if ((window.frontendVersion && window.frontendVersion !== version) || (localStorage.frontendVersion && localStorage.frontendVersion !== version)) {
      window.frontendVersion = version; // 保存 以便下次使用判断
      localStorage.frontendVersion = res.data; // 保存,以便下次使用判断
      location.reload(); // 刷新页面
    } else {
      window.frontendVersion = version; // 保存 以便下次使用判断
      localStorage.frontendVersion = res.data; // 保存,以便下次使用判断
    }
  });
};
const filterOption = (input, option) => {
  input = input.toLowerCase();
  if (typeof (option) === 'string') {
    return option.toLowerCase().indexOf(input) >= 0;
  }
  if (!option || !option.props) { return false; }
  const children = option.props.children;
  if (typeof (children) === 'string') {
    return children.toLowerCase().indexOf(input) >= 0;
  }
  if (Array.isArray(children)) {
    let result = false;
    children.forEach((a) => {
      result = result || filterOption(input, a);
    });
    return result;
  }
  if (typeof (children) === 'object') {
    return filterOption(input, children);
  }
};
// 按钮权限
const hasAuth = (authKey, permissionCodes) => {
  if (!permissionCodes) {
    const { farm } = store.getState();
    permissionCodes = farm.permissionCodes || [];
  }
  return !!permissionCodes.find((item) => {
    const reg = new RegExp(item);
    return reg.test(authKey);
  });
};
// 页面权限
const getNoAuthPath = ({
  isVirtual, isExternal, ammeterFlag, repairFlag, isInternal
}, obsFlag) => {
  //虚拟矿场隐藏的菜单
  const arr = isVirtual ? ['/customer', '/nuc/table-list', '/nuc/reported-list', '/power', '/electricity-meter', '/meter-location', '/permission-control', '/temperature-humidity', '/temperature-humidity-list'] : [];
  //观察者模式下隐藏的菜单
  const arr1 = obsFlag ? ['/summary-view', '/customer-summary-view'] : [];
  //外部矿场隐藏的菜单(预发环境不展示老资产菜单'/asset', '/asset-daily'),
  const arr2 = isExternal ? ['/asset-operation', '/asset-status', '/on-shelf', '/off-shelf-repair'] : ['/asset', '/asset-daily'];
  //德州矿场展示的菜单
  const arr3 = ammeterFlag
    ? ['/electricity-fees', '/customer-electricity-fees']
    : ['/electricity', '/power', '/power-customer', '/electricity-meter', '/meter-location', '/permission-control', '/temp-humi-management', '/temperature-humidity', '/temperature-humidity-list'];
  //维修系统展示的菜单
  const arr4 = repairFlag ? [] : ['/repair', '/repair-list', '/repair-distribute', '/repair-miner', '/repair-testing', '/repair-delivery', '/repair-management', '/repair-scrap', '/repair-report-customer', '/repair-report-repairtech', '/repair-report-onrack', '/repair-customer'];
  //内部矿场和内部测试矿场展示的菜单
  const arr5 = isInternal ? [] : ['/operation-weekly-report'];
  return [...arr, ...arr1, ...arr2, ...arr3, ...arr4, ...arr5];
};

window.common = {
  locale,
  api,
  util,
  enums,
  ...links,
  checkVersion,
  filterOption,
  hasAuth,
  getNoAuthPath,
  WebSocketClient
};

export default window.common;

export {
  locale,
  api,
  util,
  enums,
  checkVersion,
  filterOption,
  hasAuth,
  getNoAuthPath,
  WebSocketClient
};
